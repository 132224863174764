import { useRouter } from 'next/router'
import ROUTES from '@constants/constRoutes'
import { useEffect } from 'react'
import useUserInfo from 'containers/hooks/useUserInfo'

// test by curl
// curl http://127.0.0.1:8000/graphql -X POST -H "Content-Type: application/json" -d '{
//   "query": "query Root { users { edges { node { email } } } }"
// }'

// tested GET_USER_BY_ID by graphql
const Main = () => {
  const router = useRouter()
  const userToken = useUserInfo.loadToken()
  useEffect(() => {
    if (router && userToken) {
      router.replace({ pathname: ROUTES.DASHBOARD })
    }
  }, [router])
  return null
}

export default Main
